import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import clsx from "clsx"

import { imgNode, ViewProps } from "./image-types"

const useStyles = makeStyles(theme => ({
  borderRadiusTop: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },

  borderRadiusBottom: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}))

const ImageView = ({
  src,
  alt,
  borderRadiusBottom = true,
  borderRadiusTop = true,
}: ViewProps) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query MyQuery {
      allImageSharp {
        edges {
          node {
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const imgData = data.allImageSharp.edges.find(
    (x: imgNode) => x.node.fluid.originalName === src
  )

  return (
    <Img
      /*
        ObjectFit is set to 'cover' by default, but this can lead to blurry images if the image
        contains text or thin lines. The bug only occurs on certain widths. Bug reproduced on Chrome 85.
        Setting ObjectFit to 'contain' seems to fix the problem.
       */
      imgStyle={{ objectFit: `contain` }}
      fluid={imgData.node.fluid}
      alt={alt}
      className={clsx(
        borderRadiusTop && classes.borderRadiusTop,
        borderRadiusBottom && classes.borderRadiusBottom
      )}
    />
  )
}

export default ImageView
